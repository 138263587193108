import ReactDOM from 'react-dom';
import './index.css';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { darkTheme } from '@syns-platform/perspective-theme';
import { TokenManager } from '@syns-platform/local-login';
import isPropValid from '@emotion/is-prop-valid';

function importBuildTarget() {
  if (process.env.REACT_APP_BUILD_TARGET === 'operator') {
    return import('./components/App/OperatorApp');
  } else if (process.env.REACT_APP_BUILD_TARGET === 'client') {
    return import('./components/App/ClientApp');
  } else {
    return Promise.reject(new Error('No such build target: ' + process.env.REACT_APP_BUILD_TARGET));
  }
}

interface TokenManagerInitProps {
  renewUrl: string;
  getSessionUrl: string;
  logoutUrl: string;
  switchOrganizationContextUrl: string;
}
TokenManager.initialize({
  renewUrl: `${process.env.REACT_APP_SYNS_CITADEL_BASE_URL}/v2/identity/token/renew`,
  getSessionUrl: `${process.env.REACT_APP_SYNS_CITADEL_BASE_URL}/v2/identity/session`,
  logoutUrl: `${process.env.REACT_APP_SYNS_CITADEL_BASE_URL}/v2/identity/operators/logout`,
} as TokenManagerInitProps);

// This implements the default behavior from styled-components v5
function shouldForwardProp<T>(propName: T, target: T) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName as string);
  }
  // For other elements, forward all props
  return true;
}

// Import the entry point and render it's default export
importBuildTarget().then(({ default: Environment }) =>
  ReactDOM.render(
    <StyleSheetManager shouldForwardProp={shouldForwardProp} enableVendorPrefixes>
      <ThemeProvider theme={darkTheme}>
        <Environment />
      </ThemeProvider>
    </StyleSheetManager>,
    document.getElementById('root')
  )
);
